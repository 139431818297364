<template>
    <div>
        this is netuser
    </div>
</template>

<script>
    export default {
        name: "userNet"
    }
</script>

<style scoped>

</style>
